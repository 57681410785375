<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useUser } from '~/stores/user'

const localePath = useLocalePath()

const { t } = useI18n()

const { isLoggedIn } = storeToRefs(useUser())

useSeoMeta({
  title: t('pages.login.meta.title'),
  description: t('pages.index.meta.description'),
})

// watch(
//   isLoggedIn,
//   (value) => {
//     if (value) {
//       navigateTo(localePath({ name: 'index' }))
//     }
//   },
//   { immediate: true }
// )
</script>

<template>
  <main>
    <UiBanner :title="$t('pages.login.label')" />
    <div>
      <div class="container py-10">
        <div
          v-if="isLoggedIn"
          class="mx-auto flex w-fit flex-col items-center gap-5"
        >
          <p class="text-2xl font-bold">
            {{ $t('error.alreadySignedIn') }}
          </p>
          <NuxtLink :to="localePath({ name: 'index' })">
            <UiButtonBlack>{{ $t('error.backToHomepage') }}</UiButtonBlack>
          </NuxtLink>
        </div>
        <div v-else class="mx-auto w-full md:w-2/3 lg:w-1/2">
          <Login />
        </div>
      </div>
    </div>
  </main>
</template>
